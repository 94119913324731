import * as Yup from 'yup';

export const bookingSchema = Yup.object().shape({
    serviceDuration: Yup.string().required('Service duration is required.'),
    startDate: Yup.date().required('Please select a date range.'),
    endDate: Yup.date().required('Please select a date range.'),
    fullName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Only letters and spaces are allowed.')
        .required('Full name is required.'),
    email: Yup.string()
        .email('Invalid email address.')
        .required('Email is required.'),
    phone: Yup.string()
        .matches(/^\+?\d+$/, 'Only digits and an optional "+" sign are allowed.')
        .required('Phone number is required.'),
    message: Yup.string().max(500, 'Message cannot exceed 500 characters.'),
});
