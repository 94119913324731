import React from 'react';

function BookingFormFields({ values, errors, touched, handleChange, handleBlur }) {
    return (
        <>
            {/* Full Name */}
            <div className="form-group">
                <label htmlFor="fullName">Full Name</label>
                <div className="input-container">
                    <input
                        type="text"
                        id="fullName"
                        name="fullName"
                        placeholder={
                            errors.fullName && touched.fullName && !values.fullName
                                ? errors.fullName
                                : "John Doe"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fullName}
                        className={
                            errors.fullName && touched.fullName && !values.fullName ? "input-error" : ""
                        }
                        style={{
                            textAlign: errors.fullName && touched.fullName && !values.fullName ? "center" : "left",
                        }}
                    />
                </div>
            </div>

            {/* Email Address */}
            <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <div className="input-container">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder={
                            errors.email && touched.email && !values.email
                                ? errors.email
                                : "example@domain.com"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className={
                            errors.email && touched.email && !values.email ? "input-error" : ""
                        }
                        style={{
                            textAlign: errors.email && touched.email && !values.email ? "center" : "left",
                        }}
                    />
                </div>
            </div>

            {/* Phone Number */}
            <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <div className="input-container">
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder={
                            errors.phone && touched.phone && !values.phone
                                ? errors.phone
                                : "+1234567890"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        className={
                            errors.phone && touched.phone && !values.phone ? "input-error" : ""
                        }
                        style={{
                            textAlign: errors.phone && touched.phone && !values.phone ? "center" : "left",
                        }}
                    />
                </div>
            </div>

            {/* Message */}
            <div className="form-group">
                <label htmlFor="message">Message (optional)</label>
                <div className="input-container">
                    <textarea
                        id="message"
                        name="message"
                        placeholder={
                            errors.message && touched.message && !values.message
                                ? errors.message
                                : "Any special requests or notes..."
                        }
                        rows="4"
                        maxLength="500"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        className={
                            errors.message && touched.message && !values.message ? "input-error" : ""
                        }
                        style={{
                            textAlign: errors.message && touched.message && !values.message ? "center" : "left",
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default BookingFormFields;
