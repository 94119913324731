import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { bookingSchema } from '../../validations/bookingSchema';
import BookingDateRangePicker from '../../components/DateRangePicker/BookingDateRangePicker';
import BookingFormFields from './BookingFormFields';
import BookingFormSuccess from './BookingFormSuccess';
import { loadFormData, saveFormData, clearFormData } from '../../utils/storage';
import './BookingForm.css';

const LOCAL_STORAGE_KEY = 'nkyachtbookingFormData';

function BookingForm({ onClose, selectedPackage }) {
    const [submitted, setSubmitted] = useState(false);
    const [dateTouched, setDateTouched] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const savedValues = loadFormData(LOCAL_STORAGE_KEY) || {};

    if (savedValues.startDate) {
        savedValues.startDate = new Date(savedValues.startDate);
    }
    if (savedValues.endDate) {
        savedValues.endDate = new Date(savedValues.endDate);
    }

    const formik = useFormik({
        initialValues: {
            serviceDuration: '4h',
            startDate: null,
            endDate: null,
            fullName: '',
            email: '',
            phone: '',
            message: '',
            ...savedValues,
        },
        validationSchema: bookingSchema,
        onSubmit: async (formValues) => {
            if (!formValues.startDate || !formValues.endDate) {
                formik.setFieldError('startDate', 'Please select a valid date range.');
                formik.setFieldError('endDate', 'Please select a valid date range.');
                return;
            }

            const dataToSend = {
                ...formValues,
                package: selectedPackage
            };

            try {
                const response = await fetch('https://nkyacht.com/mail/bookingScript.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataToSend),
                });
                const result = await response.json();
                if (!result.success) {
                    alert('Error: ' + (result.error || 'Unable to send booking request'));
                    return;
                }
            } catch (error) {
                console.error('Error submitting booking:', error);
                alert('An error occurred while sending your booking request.');
                return;
            }

            console.log('Booking Submitted:', formValues);
            setSubmitSuccess(true);
            clearFormData(LOCAL_STORAGE_KEY);
            setTimeout(() => {
                formik.resetForm();
                onClose();
            }, 3000);
        },
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    useEffect(() => {
        saveFormData(LOCAL_STORAGE_KEY, values);
    }, [values]);

    useEffect(() => {
        if (selectedPackage) {
            if (selectedPackage === '4-Hour Journey') {
                setFieldValue('serviceDuration', '4h');
            } else if (selectedPackage === '6-Hour Adventure') {
                setFieldValue('serviceDuration', '6h');
            } else if (selectedPackage === '8-Hour Escape') {
                setFieldValue('serviceDuration', '8h');
            } else if (selectedPackage === 'Overnight Retreat') {
                setFieldValue('serviceDuration', 'overnight');
            }
        }
    }, [selectedPackage, setFieldValue]);

    const validateDateRange = () => {
        const { startDate, endDate } = values;
        if (!submitted && !dateTouched) return '';
        if (!startDate || !endDate) return 'Please select a valid date range.';
        if (endDate < startDate) return 'End date cannot be before start date.';
        return '';
    };
    const dateError = validateDateRange();

    return (
        <div className="booking-form-container">
            {submitSuccess ? (
                <BookingFormSuccess />
            ) : (
                <form
                    onSubmit={(e) => {
                        setSubmitted(true);
                        handleSubmit(e);
                    }}
                    noValidate
                >
                    {/* Service Duration Dropdown */}
                    <div className="form-group">
                        <label htmlFor="serviceDuration">Service Duration</label>
                        <select
                            id="serviceDuration"
                            name="serviceDuration"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.serviceDuration}
                        >
                            <option value="4h">4 hours</option>
                            <option value="6h">6 hours</option>
                            <option value="8h">8 hours</option>
                            <option value="overnight">Overnight</option>
                        </select>
                    </div>

                    {/* Date Range Picker */}
                    <BookingDateRangePicker
                        startDate={values.startDate}
                        endDate={values.endDate}
                        setFieldValue={setFieldValue}
                        dateError={dateError}
                        onDateTouch={() => setDateTouched(true)}
                    />

                    {/* Other Form Fields */}
                    <BookingFormFields
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                    />

                    <button type="submit" className="submit-button" disabled={!!dateError}>
                        Submit
                    </button>
                </form>
            )}
        </div>
    );
}

export default BookingForm;
