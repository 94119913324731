import React, { useEffect } from 'react';
import BookingForm from '../BookingForm/BookingForm';
import './BookingModal.css';

function BookingModal({ onClose, selectedPackage }) {
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') onClose();
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [onClose]);

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content" role="dialog" aria-modal="true">
                <button className="modal-close-btn" onClick={onClose} aria-label="Close Modal">
                    &times;
                </button>
                <BookingForm onClose={onClose} selectedPackage={selectedPackage} />
            </div>
        </div>
    );
}

export default BookingModal;
