export const loadFormData = (key) => {
    try {
        const serializedData = sessionStorage.getItem(key);
        return serializedData ? JSON.parse(serializedData) : null;
    } catch (err) {
        console.error("Error loading form data", err);
        return null;
    }
};

export const saveFormData = (key, data) => {
    try {
        const serializedData = JSON.stringify(data);
        sessionStorage.setItem(key, serializedData);
    } catch (err) {
        console.error("Error saving form data", err);
    }
};

export const clearFormData = (key) => {
    try {
        sessionStorage.removeItem(key);
    } catch (err) {
        console.error("Error clearing form data", err);
    }
};
