import React, { useState, useRef, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './BookingDateRangePicker.css';

const getTomorrow = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
};

function BookingDateRangePicker({ startDate, endDate, setFieldValue, dateError, onDateTouch }) {
    const [showCalendar, setShowCalendar] = useState(false);
    const calendarRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (calendarRef.current && !calendarRef.current.contains(e.target)) {
                setShowCalendar(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSelect = (ranges) => {
        const { startDate, endDate } = ranges.selection;
        setFieldValue('startDate', startDate);
        setFieldValue('endDate', endDate);
    };

    const handleInputClick = () => {
        setShowCalendar((prev) => !prev);
        if (onDateTouch) onDateTouch();
    };

    const formattedRange = startDate && endDate
        ? `${format(startDate, 'MMM d, yyyy')} - ${format(endDate, 'MMM d, yyyy')}`
        : '';

    return (
        <div className="single-range-picker">
            <label>Date Range</label>
            <div className="range-input-wrapper">
                <input
                    type="text"
                    readOnly
                    className={`range-input ${dateError ? 'input-error' : ''}`}
                    placeholder={(!startDate && !endDate && dateError) ? dateError : 'Select Date Range'}
                    value={startDate && endDate ? formattedRange : ''}
                    onClick={handleInputClick}
                    style={{
                        textAlign: (!startDate && !endDate && dateError) ? 'center' : 'left',
                    }}
                />
            </div>
            {showCalendar && (
                <div className="calendar-popover" ref={calendarRef}>
                    <DateRange
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={[
                            {
                                startDate: startDate || getTomorrow(),
                                endDate: endDate || getTomorrow(),
                                key: 'selection',
                            },
                        ]}
                        minDate={getTomorrow()}
                        direction="horizontal"
                        rangeColors={['#a57dde']}
                    />
                </div>
            )}
        </div>
    );
}

export default BookingDateRangePicker;
