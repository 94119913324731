import React, { useState, useEffect } from 'react';
import BookingModal from './components/BookingModal/BookingModal';
import './styles/global.css';

function NKYachtBooking() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('');

  useEffect(() => {
    window.openModalWithPackage = (pkg) => {
      setSelectedPackage(pkg);
      setIsModalOpen(true);
    };
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPackage('');
  };

  return (
    <div className="app">
      {isModalOpen && (
        <BookingModal onClose={closeModal} selectedPackage={selectedPackage} />
      )}
    </div>
  );
}

export default NKYachtBooking;
