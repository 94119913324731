import React from 'react';

function BookingFormSuccess() {
    return (
        <div className="booking-success">
            <h3>Thank you!</h3>
            <p>Your booking has been submitted successfully.</p>
        </div>
    );
}

export default BookingFormSuccess;
